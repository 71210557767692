import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { routes } from './router/routes';
import store from './store';

Vue.use(VueRouter);

Vue.config.productionTip = false;

const router = new VueRouter({
  routes: routes,
  mode: 'history',
});

new Vue({
  el: '#app',
  router: router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
